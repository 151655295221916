import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "read-confirmations---macro-documentation"
    }}>{`Read Confirmations - Macro Documentation`}</h1>
    <br />
    <p>{`You can place the Read Confirmation Macro anywhere on your Confluence page.
This macro will render the read confirmation button and the readers list - `}<strong parentName="p">{`but only if there is an active read confirmation for the current page`}</strong>{`. Read the
`}<a parentName="p" {...{
        "href": "/readconfirmations"
      }}>{`introduction`}</a>{` for more information.`}</p>
    <br />
You can include the macros in templates and embed it within your content.<br />
    <Image width={'80%'} src='/public/Macro.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      